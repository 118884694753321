@font-face {
  font-family: "Source";
  src: local("MyFont"),
    url("./Components/fonts/SourceCodePro-Regular.woff") format("woff");
}

body {
  font-family: "Source";
  background-color: black;
}

:root {
  --light-grey: #80808024;
}

h1,
h3,
p {
  color: white;
}

.header-wrapper {
  width: 100vw;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 5rem;
  position: sticky;
  top: 0rem;
  z-index: 3;
  padding-bottom: 10px;
  background-color: black;
  border-bottom: 1px solid var(--light-grey);
}

.welcome-wrapper {
  margin-bottom: 10rem;
  margin-top: 11vh;
  height: 40vh;
  text-align: center;

  .welcome-logo {
    height: 20rem;
  }
}

.welcome-wrapper .darkmode-toggle {
  margin-top: 20vh;
}

.learn-more {
  margin-top: 14vh;
}

.welcome-wrapper a {
  text-decoration: none;
  color: white;
  font-size: 22px;
  padding: 10px;
  font-family: "Source";
}

#cursor {
  border-left: 0.1em solid #fcfdfc;
  animation: blink 0.7s steps(1) infinite;
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.container {
  position: relative;
}

.page {
  position: relative;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.navigation-wrapper {
  position: absolute;
  right: 68px;
  width: 80%;
  max-width: 27rem;
  background-color: black;
  height: 2.5rem;
  padding-top: 40px;
  transition: width 2s;
  grid-template-columns: 33% 33% 33%;
  display: grid;
}

.navigation-wrapper .active {
  color: black;
  background-color: white;
}

.navigation-wrapper#inactive {
  width: 0px;
  overflow: hidden;
}
.nav-menu {
  right: 0px;
}

.header-logo {
  left: 0px;
  width: 50%;
}

.header-logo > img {
  max-width: 5rem;
  margin: 1rem;
}

.pageNotFound {
  height: 56vh;
}
.pageNotFound p {
  text-align: center;
  font-size: 1.4rem;
  margin-top: 9rem;
}

.nav-menu img {
  width: 4rem;
  margin-left: 2rem;
  margin-top: 2.2rem;
  float: left;
  /* opacity: 40% !important; */
}

.nav-menu-hamburger {
  /* position: absolute; */
  float: right;
  right: 0px;
  width: 60px;
  margin-top: 2.75rem;
}

.contact-form input,
button,
.about-wrapper a,
.navigation-wrapper a {
  color: white;
  background-color: black;
  padding: 6px;
  border: 1px solid white;
  border-radius: 0.25rem;
  text-decoration: none;
  font-weight: bold;
  overflow: hidden;
  text-align: center;
  height: max-content;
  font-family: "Source";
}

.navigation-wrapper a {
  max-width: 90px;
}

.welcome-modal {
  background-color: black;
  color: white;
  height: 100vh;
}

.welcome-modal h1 {
  margin-top: 11rem;
}

.modal-wrapper a {
  margin-top: 5rem;
  border: 1px solid white;
  padding: 10px;
  border-radius: 0.25rem;
  background-color: black;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  text-decoration: none;
}

.modal-wrapper img {
  width: 16rem;
  margin-top: 4rem;
  /* opacity: 11% !important;÷ */
}
.modal-wrapper {
  text-align: center;
  padding-top: 2rem;
}

.nav-footer {
  justify-content: center;
  text-align: center;
  position: sticky;
  bottom: 0px;
  background-color: black;
  width: 100vw;

  a {
    width: fit-content;
  }
}

.nav-footer .footer-icon#instagram,
.nav-footer .footer-icon#github,
.nav-footer .footer-icon#linkedin,
.nav-footer .footer-icon#gitlab {
  width: 30px;
  margin: 20px;
  /* opacity: 44% !important; */
}

.about-wrapper:hover {
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  filter: none; /* IE 6-9 */
}

.about-blurb {
  width: 40vw;
  margin: auto;
  justify-content: center;
  text-align: center;
  margin-top: 0rem;
  padding: 0.5rem;
}

.about-wrapper .about-blurb p {
  font-size: 1.2rem;
  text-align: justify;
  padding-bottom: 4.8rem;
}

.contact a {
  color: white;
}

.contact-form {
  width: vw;
  /* margin-left: auto; */
}

.contact-form label {
  color: white;
  width: 100%;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 6px;
}

.contact-form textarea {
  height: 130px;
  background-color: black;
  color: white;
  font-family: "Source";
  font-size: 0.9rem;
  border: 1px solid white;
  border-radius: 0.25rem;
  width: 100%;
}

.contact-form input#submit-button {
  margin-top: 20px;
  width: 100%;
  padding: 12px;
  padding-left: 10px;
  justify-content: center;
  margin-left: 6px;
  cursor: pointer;
}

.contact-form input#submit-button:disabled {
  color: gray;
  border-color: gray;
}

.link-container a {
  margin-right: 7.5px;
  margin-left: 7.5px;
  font-size: 1.2rem;
}

.modal-window h3 {
  margin-top: 30vh;
  color: black;
  font-size: 2.8rem;
  color: bold;
}

.coming-soon h1 {
  text-align: center;
  margin-top: 15rem;
  font-size: 6rem;
}

.projects {
  min-height: 78vh;
}

.projects .card-wrapper {
  height: 100%;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 19rem));
  justify-content: center;
}

.projects .projects-card {
  height: 15rem;
  width: 19rem;
  border: 1px solid white;
  border-radius: 0.25rem;
  overflow: hidden;
  cursor: pointer;
  padding: 0px;
}

.projects-card h4 {
  text-align: center;
  color: white;
  font-weight: bold;
  padding-bottom: 0rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.projects-card:hover h4 {
  border-top: grey;
  background-color: white;
  color: black;
}
.projects-card div {
  overflow: hidden;
  height: 12.8rem;
}

.projects-card img {
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
}

.projects-card:hover img,
.projects-card:focus img {
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  filter: none;
  filter: none; /* IE 6-9 */
}

.projects-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.projects-modal-wrapper {
  position: absolute;
  top: 0rem;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.863);
}
.projects-modal-wrapper .projects-info-modal {
  margin-left: auto;
  margin-right: auto;
  width: 55vw;
  height: auto;
  max-height: 90%;
  margin-top: 5vh;
  border: 1px solid white;
  background-color: rgba(255, 255, 255, 0.963);
  border-radius: 0.25rem;
  padding: 5px;
  position: relative;
  overflow-y: scroll;

  .project-description{
    overflow-y: scroll;
  }
}

.carousel .thumbs-wrapper {
  margin: 0rem;
  height: 0rem;
}

.projects-info-modal div .carousel-root {
  width: 90%;
  height: 18rem;
  margin-left: 5%;
  margin-top: 3rem;
  border-radius: 0.25rem;
  border: 1px solid grey;
}
.projects-info-modal div img {
  border: 1px solid black;
  /* border-radius: 0.5rem; */
  width: 90%;
  margin-left: 5%;
  margin-right: auto;
  margin-top: 1rem;
}
.projects-info-modal div .carousel {
  margin-left: 0rem;
  border-radius: none;
  margin-top: 0rem;
}

.slide.selected {
  height: auto;
}

p.carousel-status {
  display: hidden;
  opacity: 0%;
}

div.carousel.carousel-slider {
  border-radius: 0.25rem;
}
div.carosel-image {
  width: 90%;
}
.projects-info-modal div img#BreweryFinder {
  margin-left: 3.8rem;
}

.projects-info-modal p {
  color: black;
  width: 95%;
  margin-left: 2.5%;
  text-align: justify;
}

.projects-info-modal h4 {
  color: black;
  text-align: center;
  font-size: 1.6rem;
  margin: 0rem;
  margin-top: 0.25rem;
}

.projects-info-modal .closeIcon {
  height: 25px;
  width: 25px;
  font-size: 1.2rem;
  border: none;
  padding: 0;
  border-radius: 5rem;
  background-color: rgba(0, 0, 0, 0.473);
  color: rgba(255, 255, 255, 0.527);
  top: -6px;
  right: -6px;
  position: absolute;
}

.about-wrapper {
  width: 80vw;
  margin-left: 10vw;
  margin-top: 4rem;
  padding-bottom: 2rem;
  display: inline-flex;
  flex-wrap: wrap;
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray; /* IE 6-9 */
}

.headshot {
  height: 100%;
  border-radius: 0.25rem;
  aspect-ratio: 3/4;
  max-width: 420px;
  min-width: 300px;
  width: 40vw;
  margin: auto;
}

.about-page h1 {
  margin-top: 2vw;
  margin-left: 9vw;
}

.modal-wrapper {
  min-width: 51rem;
}

.about-blurb a {
  align-items: center;
  width: 12rem;
  padding: 2rem;
}

.navigation-wrapper a:hover,
.about-blurb a:hover {
  border: 2.5px solid white;
}

.about-blurb a:hover {
  text-decoration: underline;
}

.contact-page {
  margin-top: 0px;
  height: 82vh;
  overflow: auto;
  .modal {
    text-align: center;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.9;
    transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
  }
  .contact-wrapper {
    border-radius: 0.25rem;
    max-width: 55rem;
    margin: auto;
    padding: 2rem;
    min-width: 20rem;
    display: flex;
    flex-wrap: wrap;
    border: 1px solid white;
    margin-top: 20vh;
  }
}

.contact {
  max-width: 27.5rem;
  width: 50vw;
}
.link-container {
  padding-top: 40px;
}

.coming-soon {
  height: 32rem;
}
.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  border: 0.5px solid #fafafa9c;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: black;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: black;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: black;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099e0;
  -moz-box-shadow: 0px 0px 3px 2px #0099e0;
  box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
  -moz-box-shadow: 0px 0px 5px 5px #0099e0;
  box-shadow: 0px 0px 5px 5px #0099e0;
}

.darkmode-toggle label {
  color: white;
  padding: 10px;
}

.light-mode {
  h1,
  h3,
  p,
  .contact-form input,
  button,
  .light-mode .about-wrapper a,
  .welcome-wrapper a,
  .navigation-wrapper a,
  label {
    color: black;
    background-color: white;
  }

  .navigation-wrapper .active {
    background-color: black;
    color: white;
  }
  .welcome-wrapper button {
    border: 1px solid black;
  }

  .header-wrapper {
    color: black;
    background-color: white;
  }

  .navigation-wrapper {
    background-color: white;
    a {
      border: 1px solid black;
    }
  }
  .about-wrapper a {
    border: 1px solid black;
    background-color: white;
    color: black;
  }
  .nav-footer {
    background-color: white;
    border-top: 1px solid var(--light-grey);
  }
  .contact-page,
  .contact-form input,
  .contact-form textarea,
  .projects .projects-card {
    border-color: black;
    background-color: white;
    color: black;
    h4 {
      background-color: black;
    }
  }
  .projects-card:hover h4 {
    background-color: white;
    color: black;
  }

  .contact a {
    color: black;
  }
  .contact-wrapper{
    border-color: black;
  }
}

.carousel-wrapper {
  margin-top: 26px;
  width: 100%;
  position: relative;

  .carousel-indicator {
    padding: 0px;
    margin: 0;
    margin-top: -2.5vh;
    color: white;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    opacity: 30;
    li {
      display: inline;
      font-size: 1.2rem;
      cursor: pointer;
    }
    li#selected {
      font-size: 1.2rem;
    }
  }

  .carousel-items {
    .indicator-left {
      left: 5px;
    }
    .indicator-right {
      right: 5px;
    }
    .indicator-left,
    .indicator-right {
      position: absolute;
      width: fit-content;
      color: white;
      place-items: center;
      top: 0;
      button {
        color: rgba(255, 255, 255, 0.527);
        font-size: 2rem;
        padding: 4px;
        background-color: rgba(0, 0, 0, 0.473);
        border-radius: 5rem;
        margin-top: 24vh;
        font-weight: bold;
        padding-bottom: 5px;
        border: 1px solid rgba(255, 255, 255, 0.226);
      }
      button:active {
        color: black;
        background-color: white;
      }
    }
    .carousel-card {
      border: 2px solid white;
      border-radius: 0.25rem;

      .carousel-image {
        margin: 0px;
        height: auto;
        width: 100%;
        object-fit: cover;
        vertical-align: bottom;
        border-radius: 0.25rem;
        transition: all 1s;
      }

      .current-slide-text {
        background-color: rgba(255, 255, 255, 0.616);
        color: rgba(0, 0, 0, 0.473);
        text-decoration: none;
        float: right;
        width: auto;
        padding: 10px;
        border-radius: 0rem 0rem 0rem.25rem;
        margin-top: 0rem;
      }
    }
  }
}
